import React from "react";
import PropTypes from "prop-types";
import { connectInfiniteHits } from "react-instantsearch-dom";
import Flex from "app/components/Flex";
import { ScreenLtMd } from "app/components/MediaQueries";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import MobileFilterButton from "./MobileFilterButton";
import { Waypoint } from "../Waypoint";

const InfiniteContentList = ({
  hits,
  hasMore,
  refineNext,
  openFilter,
  renderList,
  indexName,
  hideMobileFilterButton,
}) => {
  const { isFamilyModeOn } = useFamilyModeSetting();

  if (!hits) {
    return null;
  }

  return (
    <Flex>
      {renderList({
        hits,
        sentinel: <Waypoint hasMore={hasMore} fetchData={refineNext} />,
      })}
      {!isFamilyModeOn && !hideMobileFilterButton && (
        <ScreenLtMd>
          <MobileFilterButton indexName={indexName} openFilter={openFilter} />
        </ScreenLtMd>
      )}
    </Flex>
  );
};

InfiniteContentList.defaultProps = {
  hits: null,
  hasMore: false,
  hideMobileFilterButton: false,
};

InfiniteContentList.propTypes = {
  hideMobileFilterButton: PropTypes.bool,
  hits: PropTypes.instanceOf(Array),
  hasMore: PropTypes.bool,
  openFilter: PropTypes.func.isRequired,
  renderList: PropTypes.func.isRequired,
  indexName: PropTypes.string.isRequired,
};

export default connectInfiniteHits(InfiniteContentList);
